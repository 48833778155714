<template>
  <div>
    <a-form-model layout="inline" :model="formInline">
      <a-form-model-item>
        <a-input
          v-model="formInline.keyword"
          placeholder="搜索车牌号"
          @input="carNumHhanlder"
          allowClear
        >
          <template #prefix>
            <a-icon type="search" />
          </template>
        </a-input>
      </a-form-model-item>
      <a-form-model-item>
        <a-select
          v-model="formInline.deviceId"
          placeholder="请选择设备"
          style="width: 180px"
          :options="options"
          @change="carNumHhanlder"
          allowClear
        >
          <!-- <a-select-option value="dg">
              电工
            </a-select-option>
            <a-select-option value="mg">
              木工
            </a-select-option> -->
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class CarForm extends Vue {
  @Prop({ type: Array, default: () => [] }) deviceLists;
  created() {}
  mounted() {}
  get options() {
    return this.deviceLists.map(ele => ({
      value: ele.pkId,
      label: ele.deviceName,
    }));
  }
  formInline = {
    keyword: '',
    deviceId: undefined,
  };
  carNumHhanlder() {
    // console.log(this.formInline);
    this.$emit('handleSearch', this.formInline);
  }
}
</script>
<style lang="less" scoped></style>
