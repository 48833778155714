<template>
  <div :class="$style.record">
    <ul :class="$style.lists">
      <li :class="$style.item" v-for="(item, index) in carInCount" :key="index">
        <div :class="$style['item-left']">
          <x-oss-image
            v-if="item.url.length"
            basePath="/oss/oss"
            :ossPath="item.url[0] || ''"
            @click="openImg(item.url, index)"
            :class="$style['table-img']"
          />
          <div v-else :class="$style['no-img']">
            <NoImg />
          </div>
        </div>
        <div :class="$style['item-right']">
          <h3 :class="$style.title">{{ item.licensePlateNumber }}</h3>
          <span :class="$style.status">进场</span>
          <p :class="$style.time">{{ formatTime(item.time) }}</p>
        </div>
      </li>
    </ul>
    <ul :class="$style.lists">
      <li
        :class="$style.item"
        v-for="(item, index) in carOutCount"
        :key="index"
      >
        <div :class="$style['item-left']">
          <x-oss-image
            v-if="item.url.length"
            basePath="/oss/oss"
            :ossPath="item.url[0] || ''"
            @click="openImg(item.url, index)"
            :class="$style['table-img']"
          />
          <div v-else :class="$style['no-img']">
            <NoImg />
          </div>
        </div>
        <div :class="$style['item-right']">
          <h3 :class="$style.title">{{ item.licensePlateNumber }}</h3>
          <span :class="$style.status">出场</span>
          <p :class="$style.time">{{ formatTime(item.time) }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import NoImg from '../../people-management/components/noImg.vue';
@Component({
  components: {
    NoImg,
  },
})
export default class RecordInboundAndOutbound extends Vue {
  //carOutCount
  @Prop({ type: Array, default: () => [] }) carOutCount;
  @Prop({ type: Array, default: () => [] }) carInCount;
  created() {}
  mounted() {}
  openImg() {}
  formatTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '-';
  }
}
</script>
<style lang="less" module>
.record {
  display: flex;
  height: 100%;
  .lists {
    flex: 1;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0.15rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // background: linear-gradient(
    //   180deg,
    //   RGBA(20, 38, 62, 1) 9%,
    //   RGBA(24, 39, 56, 1) 43%,
    //   RGBA(12, 24, 39, 1) 91%
    // );
    li {
      display: flex;
      height: 22%;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      .item-left {
        width: 2.04rem;
        height: 100%;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        background: linear-gradient(
          180deg,
          RGBA(11, 29, 53, 1) 9%,
          RGBA(11, 29, 53, 1) 43%,
          RGBA(19, 34, 51, 1) 91%
        );
      }
      .title {
        font-size: 0.16rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #d8e1f3;
        text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.302);
      }
      .status {
        font-size: 0.14rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: #37d7db;
        text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.302);
      }
      .time {
        font-size: 0.12rem;
        font-family: 'PingFang SC';
        font-weight: 400;
        color: rgba(255, 255, 255, 0.3);
        text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.302);
        margin-top: 0.3rem;
      }
      .item-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 0.24rem;
        padding: 0.1rem 0;
      }
    }
    &:last-child {
      margin-left: 0.16rem;
    }
  }
  .table-img {
    width: 100%;
    height: 100%;
  }
  .no-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    img {
      width: 0.68rem;
      height: 0.71rem;
    }
  }
}
</style>
