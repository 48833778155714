<template>
  <div :class="$style['car-list-container']">
    <div :class="$style['car-list-item']">
      <BaseWorkerItems :configData="configDataLeft" />
    </div>
    <div :class="$style['car-list-item']">
      <BaseWorkerItems :configData="configDataRight" />
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseWorkerItems from '@/views/screen/project/people-management/components/base-worker-items';
@Component({
  components: {
    BaseWorkerItems,
  },
})
export default class CarTopLists extends Vue {
  @Prop({ type: Number, default: 0 }) clearCount;
  @Prop({ type: Number, default: 0 }) alarmCount;
  @Prop({ type: Number, default: 0 }) carPresentCount;
  @Prop({
    type: Object,
    default: () => ({
      toDayInCount: 0,
      toDayOutCount: 0,
      toDayPresentCount: 0,
      yesterdayInCount: 0,
      yesterdayOutCount: 0,
    }),
  })
  leftData;
  get configDataRight() {
    return [
      {
        title: '今日车辆清洗次数',
        value: this.clearCount,
      },
      {
        title: '今日车辆告警次数',
        value: this.alarmCount,
      },
    ];
  }
  get configDataLeft() {
    return [
      {
        title: '今日进场车辆数',
        value: this.leftData.toDayInCount,
      },
      {
        title: '今日出场车辆数',
        value: this.leftData.toDayOutCount,
      },
      {
        title: '昨日进场车辆数',
        value: this.leftData.yesterdayInCount,
      },
      {
        title: '昨日出场车辆数',
        value: this.leftData.yesterdayOutCount,
      },
      {
        title: '今日在场车辆数',
        value: this.leftData.toDayPresentCount,
      },
    ];
  }
}
</script>
<style lang="less" module>
.car-list-container {
  display: flex !important;
  .car-list-item {
    padding: 0.08rem;
    background: linear-gradient(
      180deg,
      rgba(23, 33, 46, 0) 9%,
      rgba(80, 104, 131, 0.5) 53%,
      rgba(80, 104, 131, 0) 91%
    );
    flex: 1;
    height: 1.58rem !important;
    &:last-of-type {
      margin-left: 0.16rem;
    }
  }
}
</style>
