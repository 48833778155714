<template>
  <div class="h-full overflow-hidden" style="padding-bottom:.1rem">
    <LineChart
      chart-width="100%"
      chart-height="100%"
      isSmooth
      symbol="round"
      chartRight="2%"
      yName="单位：次"
      :chart-data="inboundAndOutboundChartData"
      :show-legend="true"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import LineChart from '@/views/screen/e-charts/line-chart.vue';
@Component({
  components: {
    LineChart,
  },
})
export default class TrendInboundAndOutbound extends Vue {
  @Prop({ type: Object, data: () => {} }) data;
  created() {}
  mounted() {}

  get inboundAndOutboundChartData() {
    const data = [];
    this.data.datas &&
      this.data.datas.forEach(item => {
        if (item.tag === 'IN') {
          data.push({
            name: '进场车辆',
            data: item.values,
            unit: '辆',
          });
        } else {
          data.push({
            name: '出场车辆',
            data: item.values,
            unit: '辆',
          });
        }
      });
    return [
      {
        time: this.data.times,
        data,
      },
    ];
  }
}
</script>
<style lang="less" module></style>
