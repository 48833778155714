<template>
  <div :class="$style.wrapper">
    <div :class="$style.top">
      <LayoutCard :showHeader="false" :class="$style.card">
        <CarTopLists
          :clearCount="clearCount"
          :alarmCount="alarmCount"
          :leftData="leftData"
        />
      </LayoutCard>
    </div>
    <div :class="$style.content">
      <div :class="[$style.item1, $style.item]">
        <LayoutCard title="车辆进出场记录" :class="$style.card">
          <RecordInboundAndOutbound
            style="cursor:pointer"
            @click.native="cellClick('carInAndOut')"
            :carOutCount="carOutCount"
            :carInCount="carInCount"
            v-if="carOutCount.length && carInCount.length"
          />
          <div v-else>
            <EmptyContent />
          </div>
        </LayoutCard>
      </div>
      <div :class="[$style.item2, $style.item]">
        <LayoutCard title="进出场车辆趋势" :class="$style.card">
          <template #action>
            <LayoutSelect
              :options="dateList"
              defaultValue="SEVEN_DAY"
              @change="val => handleSelect(val)"
            ></LayoutSelect>
          </template>
          <TrendInboundAndOutbound
            :data="trendCarData"
            v-if="
              trendCarData && trendCarData.datas && trendCarData.datas.length
            "
          />
          <div v-else>
            <EmptyContent />
          </div>
        </LayoutCard>
      </div>
      <div :class="[$style.item3, $style.item]">
        <LayoutCard title="车辆清洗记录" :class="$style.card">
          <ScrollTable
            v-if="scrollTbaleData.length"
            :columns="carCleaningScrollTbaleColumns"
            :data="scrollTbaleData"
            @cellClick="cellClick('carClearing')"
            :infiniteScroll="false"
          ></ScrollTable>
          <div v-else>
            <EmptyContent />
          </div>
        </LayoutCard>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import LineChart from '@/views/screen/e-charts/line-chart';
import { LayoutCard, LayoutSelect, LayoutTabs } from '../../layout';
import CarTopLists from './components/car-top-lists.vue';
import TrendInboundAndOutbound from './components/trendInboundAndOutbound';
import RecordInboundAndOutbound from './components/recordInboundAndOutbound';
import ScrollTable from '@/views/screen/components/scroll-table.vue';
import { createModal } from '@triascloud/x-components';
import { PublicSize } from '@/views/green-construction/components/utils';
import PeopleManageMentDialog from '../people-management/components/peopleManageMentDialog';
import CarForm from './components/car-form.vue';
import { debounce } from 'lodash';
import {
  // getVehicleCountByTime,
  getVehicleTrendByTime,
  // getVehiclePresentVehicles,
  getVehicleWashCount,
  getVehicleAccessRecord,
  getLastWashRecord,
  getPageWashRecordLists,
  getDeviceLists,
  getLastRecordCarLists,
  getYesAndTodayVehiclesCount,
} from '@/services/screen/jiangsu/vehicle';
import { crossStorageModule } from '@/enum/store.js';
import { formatSeconds } from '../../utils';
@Component({
  components: {
    LineChart,
    LayoutCard,
    LayoutTabs,
    LayoutSelect,
    CarTopLists,
    TrendInboundAndOutbound,
    RecordInboundAndOutbound,
    ScrollTable,
    CarForm,
  },
})
export default class CarManagementIndex extends Vue {
  @crossStorageModule.State('tenant') tenant;
  created() {
    this.init();
  }
  init() {
    // this.getVehicleCountByTimeData();
    this.getVehicleTrendByTimeData();
    this.getVehicleWashCountData();
    this.getVehicleAccessRecordData();
    this.getLastWashRecordData();
    this.getPageWashRecordListsData();
    // this.getPageInAndOutRecordListsData();
    // this.getVehiclePresentVehiclesData();
    this.getYesAndTodayVehiclesCountData();
  }
  mounted() {}

  get projectId() {
    return this.$store.state.screen.projectId;
  }

  // todayCarInOutCount = { in: 0, out: 0 };
  // ydCarInOutCount = { in: 0, out: 0 };
  // getVehicleCountByTimeData() {
  //   const date = this.dayjs(new Date()).format('YYYY-MM-DD');
  //   const data = {
  //     idxProjectId: this.projectId,
  //     startTime: `${date} 00:00:00`,
  //     endTime: `${date} 23:59:59`,
  //     idxTenantId: this.tenant?.pkId,
  //   };
  //   getVehicleCountByTime(data).then(res => {
  //      window.console.log(res, 'getVehicleCountByTime');
  //     this.todayCarInOutCount = res;
  //   });
  //   const date1 = this.dayjs(new Date())
  //     .subtract(1, 'day')
  //     .format('YYYY-MM-DD');
  //   const data1 = {
  //     idxProjectId: this.projectId,
  //     startTime: `${date1} 00:00:00`,
  //     endTime: `${date1} 23:59:59`,
  //     idxTenantId: this.tenant?.pkId,
  //   };
  //   getVehicleCountByTime(data1).then(res => {
  //     //window.console.log(res, 'getVehicleCountByTime');
  //     this.ydCarInOutCount = res;
  //   });
  // }
  //车辆在场总数
  //
  // getVehiclePresentVehiclesData() {
  //   const data = {
  //     idxProjectId: this.projectId,
  //     idxTenantId: this.tenant?.pkId,
  //   };
  //   getVehiclePresentVehicles(data).then(res => {
  //     window.console.log(res, 'getVehiclePresentVehicles');
  //     this.carPresentCount = res;
  //   });
  // }
  //【大屏-进场车次】获取今日-昨日 所有车辆 入场-出场 的统计）
  leftData = {};
  carPresentCount = 0;
  async getYesAndTodayVehiclesCountData() {
    const data = {
      idxProjectId: this.projectId,
    };
    const res = await getYesAndTodayVehiclesCount(data);
    // window.console.log(res, 'getYesAndTodayVehiclesCountData');
    this.leftData = res;
  }
  //今日车辆清洗与警告
  clearCount = 0;
  alarmCount = 0;
  getVehicleWashCountData() {
    //清洗
    const data = {
      idxProjectId: this.projectId,
      idxTenantId: this.tenant?.pkId,
      isAlarm: false,
    };
    getVehicleWashCount(data).then(res => {
      this.clearCount = res;
      //window.console.log(res, 'getVehicleWashCount1');
    });
    //警告
    const data1 = {
      idxProjectId: this.projectId,
      idxTenantId: this.tenant?.pkId,
      isAlarm: true,
    };
    getVehicleWashCount(data1).then(res => {
      this.alarmCount = res;
      //window.console.log(res, 'getVehicleWashCount2');
    });
  }
  //进出场记录5条数据
  carInCount = [];
  carOutCount = [];
  getVehicleAccessRecordData() {
    const data = {
      idxProjectId: this.projectId,
      idxTenantId: this.tenant?.pkId,
      current: 1,
      size: 4,
    };
    getLastRecordCarLists(Object.assign({}, data, { direction: 'IN' })).then(
      res => {
        this.carInCount = res ? res.slice(0, 4) : [];
      },
    );
    getLastRecordCarLists(Object.assign({}, data, { direction: 'OUT' })).then(
      res => {
        this.carOutCount = res ? res.slice(0, 4) : [];
      },
    );
  }
  //进出场记录弹窗分页
  total = 0;
  pagination = {
    pageSize: 10,
    current: 1,
  };
  keyword = '';
  deviceId = '';

  async getPageInAndOutRecordListsData() {
    const data = {
      idxProjectId: this.projectId,
      idxTenantId: this.tenant?.pkId,
      current: this.pagination.current,
      size: this.pagination.pageSize,
      keyword: this.keyword,
      deviceId: this.deviceId,
    };
    try {
      this.loading = true;
      const { records, total } = await getVehicleAccessRecord(data);
      this.carClearingData = records;
      this.total = total;
    } catch {
      this.loading = false;
    }
  }
  //分页切换
  dialogType = 'carClearing';
  changePagination(data) {
    this.pagination.current = data.current;
    this.pagination.pageSize = data.pageSize;
    if (this.dialogType === 'carClearing') {
      this.getPageWashRecordListsData();
    } else {
      this.getPageInAndOutRecordListsData();
    }
  }
  //趋势图
  vehicleTrendByTime = 'SEVEN_DAY';
  trendCarData = {};
  getVehicleTrendByTimeData() {
    const data = {
      idxProjectId: this.projectId,
      idxTenantId: this.tenant?.pkId,
      searchDay: this.vehicleTrendByTime,
    };
    getVehicleTrendByTime(data).then(res => {
      // window.console.log(res, 'getVehiclePresentCount222');
      this.trendCarData = res;
    });
  }

  handleSelect(val) {
    // console.log('value', val);
    this.vehicleTrendByTime = val;
    this.getVehicleTrendByTimeData();
  }
  //车辆清洗记录
  scrollTbaleData = [];
  getLastWashRecordData() {
    const data = {
      idxProjectId: this.projectId,
      idxTenantId: this.tenant?.pkId,
    };
    getLastWashRecord(data).then(res => {
      //window.console.log(res, 'getLastWashRecord');
      this.scrollTbaleData = res;
    });
  }
  //分页查询清洗记录弹窗
  carClearingData = [];
  cPkId = '';
  cKeyword = '';
  async getPageWashRecordListsData() {
    const data = {
      idxProjectId: this.projectId,
      idxTenantId: this.tenant?.pkId,
      current: this.pagination.current,
      size: this.pagination.pageSize,
      pkId: this.cPkId,
      keyword: this.cKeyword,
    };
    try {
      this.loading = true;
      const { records, total } = await getPageWashRecordLists(data);
      this.carClearingData = records;
      this.total = total || 0;
    } finally {
      this.loading = false;
    }
  }
  todayStatistics = [];
  carCleaningScrollTbaleColumns = [
    {
      title: '车牌号',
      key: 'plateNum',
    },
    {
      title: '清洗时长',
      key: 'ltime',
      format: text => (text ? formatSeconds(text) : 0),
    },
    {
      title: '清洗结果',
      key: 'alarmStat',
      //1 时间不足,0 正常
      format: text => (text === 1 ? '时间不足' : '正常'),
    },
    {
      title: '开始清洗时间',
      key: 'timeIn',
      format: text =>
        text ? this.dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '',
    },
  ];
  dateList = [
    {
      label: this.$t('screen.sevenDay'),
      value: 'SEVEN_DAY',
    },
    {
      label: this.$t('screen.thirtyDay'),
      value: 'THIRTY_DAY',
    },
  ];

  handleSearch(e) {
    this.total = 0;
    this.pagination = {
      pageSize: 10,
      current: 1,
    };
    if (this.dialogType === 'carClearing') {
      this.cPkId = e.deviceId;
      this.cKeyword = e.keyword;
      this.getPageWashRecordListsData();
    } else {
      this.keyword = e.keyword;
      this.deviceId = e.deviceId;
      this.getPageInAndOutRecordListsData();
    }
  }

  carInAndOutColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 60,
      key: 'index',
      customRender(text, record, index) {
        return index + 1;
      },
    },
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      key: 'deviceName',
      width: 160,
    },
    {
      title: '设备码',
      dataIndex: 'deviceCode',
      key: 'deviceCode',
      width: 160,
    },
    {
      title: '车牌号',
      dataIndex: 'licensePlateNumber',
      key: 'licensePlateNumber',
      width: 180,
    },
    {
      title: '进出场',
      dataIndex: 'direction',
      key: 'direction',
      customRender: text => (text === 'IN' ? '进场' : '出场'),
      width: 90,
    },
    {
      title: '进出场时间',
      dataIndex: 'time',
      key: 'time',
      width: 200,
      customRender: text => this.dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '进出场图片',
      scopedSlots: { customRender: 'images' },
    },
  ];
  carCleaningColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 60,
      key: 'index',
      customRender(text, record, index) {
        return index + 1;
      },
    },
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      key: 'deviceName',
      width: 200,
    },
    {
      title: '设备码',
      dataIndex: 'devsn',
      key: 'devsn',
      width: 200,
    },

    {
      title: '车牌号',
      dataIndex: 'plateNum',
      key: 'plateNum',
      width: 200,
    },
    {
      title: '开始清洗时间',
      dataIndex: 'timeIn',
      key: 'timeIn',
      width: 200,
      customRender: text => this.dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '结束清洗时间',
      dataIndex: 'timeOut',
      key: 'timeOut',
      width: 200,
      customRender: text => this.dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '清洗时长',
      dataIndex: 'ltime',
      width: 200,
      customRender: text => (text ? formatSeconds(text) : 0),
    },
    {
      title: '清洗结果',
      dataIndex: 'alarmStat',
      width: 200,
      key: 'alarmStat',
      customRender: text => (text === 1 ? '时间不足' : '正常'),
    },
    {
      title: '清洗前图片',
      dataIndex: 'inImg',
      width: 200,
      scopedSlots: { customRender: 'inImg' },
    },
    {
      title: '清洗后图片',
      dataIndex: 'outImg',
      width: 200,
      scopedSlots: { customRender: 'inImg' },
    },
  ];

  deviceLists = [];
  async getDeviceListsData(deviceGroup) {
    const params = {
      deviceGroup,
      idxProjectId: this.projectId,
    };
    const data = await getDeviceLists(params).catch(err => err);
    this.deviceLists = data;
  }
  loading = false;
  async cellClick(type) {
    this.dialogType = type;
    let title = '',
      columns = '',
      deviceGroup = '';
    this.total = 0;
    this.pagination = {
      pageSize: 10,
      current: 1,
    };
    if (type === 'carClearing') {
      title = '车辆清洗记录';
      columns = this.carCleaningColumns;
      deviceGroup = 'VC';
      await this.getPageWashRecordListsData();
    } else {
      title = '车辆进出场记录';
      columns = this.carInAndOutColumns;
      deviceGroup = 'VL';
      await this.getPageInAndOutRecordListsData();
    }
    //获取设别列表
    this.getDeviceListsData(deviceGroup);
    createModal(
      () => (
        <PeopleManageMentDialog
          columns={columns}
          showForm={false}
          showAction={false}
          scopedSlots={{
            header: () => (
              <CarForm
                deviceLists={this.deviceLists}
                onHandleSearch={debounce(this.handleSearch, 1000)}
              />
            ),
          }}
          data={this.carClearingData}
          total={this.total}
          onChangePagination={this.changePagination}
          loading={this.loading}
        />
      ),
      {
        title,
        width: PublicSize.MoreLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      },
    );
  }
}
</script>

<style lang="less" module>
.wrapper {
  height: 100%;
  .content {
    display: grid;
    height: calc(100% - 1.6rem);
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(2, 50%);
    // grid-gap: 0.01rem;
    .item {
      padding: 0.08rem 0;
      background: linear-gradient(
        180deg,
        rgba(23, 33, 46, 0) 9%,
        rgba(80, 104, 131, 0.5) 53%,
        rgba(80, 104, 131, 0) 91%
      );
    }
    .item1 {
      grid-row-start: span 2;
      margin-right: 0.08rem;
    }
    .item2 {
      padding-bottom: 0;
    }
    .item3 {
    }
  }
}
</style>
